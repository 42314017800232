import React from 'react'
import EnrollmentPage from "../Components/EnrollmentPage"

function AdmissionPage() {
  return (
    <div>
    <EnrollmentPage />
    </div>
  )
}

export default AdmissionPage